//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ventaDetalle",
  props: {
    venta: {type: Object, default: () => {return {}}},
    model: Boolean,
    tipo: String,
    pagos: {type: Array, default: () => []},
    productos: {type: Array, default: () => []},
  },
  data() {
    return {
      columnas: [
        {label: "folio", field: "folio", align: "left", headerClasses: "w69", format: val => val.toString().padStart(6, "0")},
        {label: "forma", field: "formaPagoDescripcion", align: "left", headerClasses: "w125"},
        {label: "tipo", field: "folio", align: "left", headerClasses: "w88", format: () => "Ingreso"},
        {label: "monto", field: "importePago", align: "right", headerClasses: "w88", format: val => this.accounting.formatMoney(val)},
        {name: "status", align: "left", headerClasses: "w50"},
        {label: "aplicación", field: "fechaAplicacion", align: "left", headerClasses: "w95", format: val => this.dateFormat(val, 2, false)},
        {label: "registro", field: "registroFecha", align: "left", headerClasses: "w148", format: val => this.dateFormat(val, 2)},
        {label: "autor", field: "autor", align: "center", headerClasses: "w63"},
      ],
      columnasProductos: [
        {label: "clave", field: "claveInterna", align: "left", headerClasses: "w125"},
        {label: "descripción", field: "descripcionExtendida", align: "left", headerClasses: "w360"},
        {label: "cantidad", field: "cantidad", align: "center", headerClasses: "w88", format: val => this.accounting.formatNumber(val)},
        {label: "Subtotal", field: "subtotal", align: "right", headerClasses: "w88", format: val => this.accounting.formatMoney(val)},
        {label: "total", field: "importeTotalConIva", align: "right", headerClasses: "w88", format: val => this.accounting.formatMoney(val)},
      ],
      ventaDetalleModal: false,
    }
  },
  watch: {
    model(value) {
      this.ventaDetalleModal = value;
    }
  },
  methods: {
    isEmpty: _.isEmpty,
    onClose() {this.$emit("on-close");},
    obtenerLotesSeries() {
      switch (Number(this.venta.entregaProductos)) {
        case 100: return "Pendiente";
        case 200: return "Entregados";
        case 300:default: return "No aplica";
        case 400: return "En proceso";
      }
    }
  }
}
